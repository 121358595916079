@import "@fontsource/atkinson-hyperlegible";

.the-big-container{
  height:auto;
  width:auto;
  background-color: #f8f9fa;
}

.drpmenu{
  display: none;
  position: absolute;
  background-color: #DA291C;
  width: 10rem;
  box-shadow: 0.75rem 0.5rem 0.75rem grey;
  z-index: 10;
}

body, html {
  background-color:#f8f9fa;
}

a {
  color: white;
}

a:hover {
  color: #f3c3a9;
}

.banner-overlay{
  background-color: rgba(31,31,31,0.59);
  position: absolute;
  width: 100%;
  height: 15.625rem;
}

.banner-text{
  font-family: "good_timing";
  text-align: center;
  font-size: 2.5rem;
  margin-top:6.25rem;
  color:white;
}

.drpmenu a{
  display: block;
  color: white;
  padding: 0.25rem 0.75rem;
  text-align: center;
  background-color:#DA291C;
  text-decoration-line: none;
  padding: 0.625rem;
}
.dropdown:hover .drpmenu{
  display: block;
  background-color: #DA291C;;
  color: "#a29d9a";
}

.drpbtn{
  color:"#a29d9a";
  text-decoration: none;
  margin-right:0.3125rem;
}

.hamburger {
  border: 0;
  height: 2.5rem;
  width: 2.5rem;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: orange;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  position: relative;
  top: 2rem;
  left: 12rem;
  transform: translateY(-50%);
  display: none;
}

@media screen and (max-width: 550px) {

  a {
    color: black;
  }
  
  a:hover {
    color: black
  }
  
  .drpmenu-ham a{
    display: block;
    color: white;
    padding: 0.25rem 0.75rem;
    text-align: center;
    background-color:#DA291C;
    text-decoration-line: none;
    padding: 0.625rem;
  }
  .dropdown-ham:hover .drpmenu-ham{
    display: block;
    background-color: #DA291C;;
    color: "#a29d9a";
  }

  
  .drpbtn-ham{
    color:black;
    text-decoration: none;
    margin-right:0.3125rem;
  }

  .hamburger {
    display: block;
  }
  .nav-link-styling{
    display:none;
  }

  .nav-link-styling-ham{
    color: black;
    text-decoration-line: none;
    padding: 1.25rem;
  }

  .nav-styling{
    text-align: center;
    flex-direction: row;
    padding-top:1.25rem;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 100%;
  }

.nav-burger{
  flex-direction: column;
  top: 3.75rem;
  left: 0;
  width: 100%;
  display:flex;
  background-color:beige;
  border-top: 0.0625rem solid black;
}

 
}

.nav-link-styling{
  color: white;
  text-decoration-line: none;
  padding: 1.25rem;
}



.nav-styling{
  text-align: center;
  flex-direction: row;
  padding-top:0.5rem;
  padding-left: 1.25rem;
  padding-right:1.25rem;
  background-color: #DA291C;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 100%;
}

.nav-image-styling{
  margin-right: 0.625rem;
  width: 4.375rem;
  height: 4.375rem;
}


body, html {

  background-color: #f8f9fa;
}

div#root {

  background-color: #f8f9fa;
  min-width:auto;
  
}

@media (max-width:960px){
  html{
    font-size:16px
  }
}

@media (max-width:961px) and (max-width: 1024px){
  html{
    font-size:16px;
  }
}

@media (max-width:1025px) and (max-width: 1281px){
  html{
    font-size:16px;
  }
}

