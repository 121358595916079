.tbl tr td {
  border: 1px solid;
}

.tbl-search tr td {
  border: 1px solid;
}

.tbl .plain_tbl tr td {
  border: none;

}
.admin-team-container{
    display: flex;
    justify-content: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: "Atkinson Hyperlegible";
}

.admin-team-btns {
    padding: 8px 8px;
    font-size: 1rem;
    border: none;
    color: white;
    outline: none;
    border-radius: 0.25rem;
    font-family: "Atkinson Hyperlegible";
    background-color: black;
    margin-left: 8px;
    width: 170px;
    height: 35px;
    margin-right:10px;
  
  }
  


  .create-team-nav-button {
margin:20px;
    font-size: 1rem;
    border: none;
    color: white;
    outline: none;
    border-radius: 0.25rem;
    font-family: "Atkinson Hyperlegible";
    background-color: black;
    width: 100px;
    height: 40px;
    display: block;
  
  }

.admin-team-switch{
    display:flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-left:105px;
    margin-top: 15px;
  }

.admin-team-title{
    font-size: 1.5rem;
    text-align: center;
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 1.25rem;
    align-items: center;
    margin-right:50px;
   }

.admin-team-table{
    margin-bottom: 30px;
   
}

.admin-team-switch-container{
    display:flex;
    flex-direction: row;
    margin-bottom: 2.5rem;
    justify-content: center;
    align-items: center;
    text-align: center;
}

