.participant-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin:auto;
  font-family: "Atkinson Hyperlegible";
}

.pr-switch{
  display:flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-left:700px;
}

.pr-table-results{
  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: "Atkinson Hyperlegible";
  margin-bottom: 1.25rem;
  margin-top: 1.25rem; 
  max-width:18.75rem;
}

.pr-container{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-family: "Atkinson Hyperlegible";
  margin-top: 1.25rem;

}

.pr-row{
  display:flex;
  flex-direction: row;
}

.pr-title {
font-size: 1.25rem;
text-align: center;
font-weight: 700;
color: black;
margin-right:1.25rem;
margin-top:0.4rem;
}






.pl-title{
  align-items: center;
  justify-content: center;
  display: flex;
  text-align: center;
  margin-top: 3.75rem;
}



.pr-table-cell-1{
  width:20px
}

.pr-table-cell-2{
  width:120px
}