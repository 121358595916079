.team-container {
    display: flex;
    justify-content: center;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-left: 10.625rem;
    font-family: "Atkinson Hyperlegible";

    
}

.team-title {
    font-size: 1.25rem;
    text-align: center;
    font-weight: 700;
    color: black;
    margin-left:10.625rem;
}