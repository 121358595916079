.vemail-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-family: "Atkinson Hyperlegible";
    margin-top: 1.25rem;
}

.vemail-title{
    font-size: 32px;
    font-weight: bold;
    font-family: "Atkinson Hyperlegible";
    margin-bottom:14px;
}

.vemail-details{
    font-size: 16px;
    font-family: "Atkinson Hyperlegible";
    line-height:20px;
}