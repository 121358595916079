
    .profile-key{
        text-align: left;
        padding-left: 1.25rem;
        padding-right: 1.25rem;
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        font-weight: 200;
        color: darkslategrey;
    }

    .profile-value{
      text-align: left;
      padding-left: 1.25rem;
      padding-right: 1.25rem;
      font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
      font-weight: 900;

      color: darkslategrey;
  }

  .profile-organization-null{
    text-align: left;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: 500;
font-style: italic;
    color: lightgray;
}

    .profile-main-container {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      text-align: center;
      font-family: "Atkinson Hyperlegible";
      margin-top:1.25rem;
    }

    .profile-title{
      font-size: 1.25rem;
      text-align: center;
      font-weight: 700;
      color: black;
    }

      .profile-container {
        max-width: 100rem;
        border-radius: 1rem;
        box-shadow: 0rem 0.625rem 0.5rem #999;
        display: flex;
        flex-direction: column;
        margin: 0.3rem;
        color: white;
        background: white;
        height: -moz-fit-content;
        height: fit-content;
        border: solid 0.0625rem #ccc;
        margin-right:1.25rem;
        margin-left:1.5625rem;
        margin-bottom:1.25rem;
        padding-bottom: 1.25rem;
      }

      .profile-new-container{
        margin-top:1.875rem;
        margin-left:3.75rem;
        margin-right:4rem;
      }

      .profile-row{
        flex-direction: row;
        display:flex;
      }

      .react-icons {
        width:2.1875rem;
        height:2.1875rem;
        margin-top: 0.375rem;
        margin-right:0.625rem;
      }
      
      
  
