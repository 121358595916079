
.admin-submission-container{
    display: flex;
    justify-content: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: "Atkinson Hyperlegible";
}

.admin-submission-container-no-scroll{
    display: flex;
    justify-content: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: "Atkinson Hyperlegible";
    overflow:hidden;
}

.admin-submission-dropdown{
    width: 300px;
    z-index: 5;
}
.admin-submission-title{
    font-size: 1.5rem;
    text-align: center;
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 1.25rem;
    margin-bottom: 2.5rem;
}

.admin-submission-table{
    margin-bottom: 30px;
}

.admin-submission-switch-container{
    display:flex;
    flex-direction: row;
    margin-bottom: 2.5rem;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.admin-submission-switch{
    display:flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-left: 600px;
    margin-top: 15px;
}

.refund-submission-confirmation-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
}
  
.refund-submission-modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    text-align: center;
    max-width: 400px; /* Adjust the max-width as needed */
    width: 100%;
}