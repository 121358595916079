.sc-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: "Atkinson Hyperlegible";

  }

  .sc-file-select{
    display: flex;
    margin-top:1.25rem;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    font-family: "Atkinson Hyperlegible";
  }

  .row>* {
    max-width: 48rem;
    align-items: center;
    align-content: center;
    text-align: left;
    flex-shrink: 0;
    margin-top: 1.25rem;
}

  .sc-form {
    font-size: 1.25rem;
    justify-content: left;
    text-align: left;
    margin-top:0.5rem;
  }

  .sc-pre-signed-select{
    margin-top:1.25rem;
    text-align: left;
    font-family: "Atkinson Hyperlegible";
  }

  .sc-title{
    font-size: 1.25rem;
    text-align: center;
    display: flex;
    justify-content: left;
    text-align: left;
    margin-top:1.25rem;
    margin-bottom:1.2rem;
  }

  .crossed-table-row {
    background-image: linear-gradient(
      to bottom right,
      transparent calc(50% - 1px),
      #696969,
      transparent calc(50% + 1px)
    );
  }

  tr th {
    font-size: 1rem;
    background-color: transparent;
    border: none;
    width: 31%;
    font-size: 1.125rem;
    padding: 0.5rem;
    border: 0.0625rem solid black;
    text-align: left;
}

.css-1azl6jz-MuiTableCell-root {
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.5rem;
  letter-spacing: 0.01071em;
  display: table-cell;
  vertical-align: inherit;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  text-align: left;
  padding: 12px;
  color: rgba(0, 0, 0, 0.87);
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.css-177gid-MuiTableCell-root {
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  display: table-cell;
  vertical-align: inherit;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  text-align: left;
  padding: 16px;
  color: rgba(0, 0, 0, 0.87);
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

tr td {
  font-size: 1rem;
  background-color: transparent;
  border: none;
  width: 21%;
  font-size: 1.125rem;
  padding: 0.75rem;
  border: 0.0625rem solid black;
  text-align: left;
}

th{
  padding-left:20px;
}

.sc-border-submission{
  font-size: 1.25rem;
  text-align: center;
  display: flex;
  justify-content: left;
  text-align: left;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding:20px;
  padding-right: 150px;
  width:1250px;
}

.sc-border{
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding:20px;
  width:1100px;
}
.sc-title-box{
  font-size: 1.32rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: left;
  text-align: left;
  margin-top:0.5rem;
}

.sc-title-row{
  font-size: 1.25rem;
  text-align: center;
  font-weight: 700;
  color: black;
  display: flex;
  justify-content: left;
  text-align: left;
  margin-top:0.4rem;
  margin-bottom:0.8rem;
}
.sc-table{
  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: "Atkinson Hyperlegible";
}


.mb-3 {
  margin-bottom: 1rem!important;
  margin-left: 11.25rem;
  margin-top: 1.5625rem;
}


.sticky-toolbar,
.sticky-toolbar-visible{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #f2f2f2;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px -1px 5px 0px rgba(0, 0, 0, 0.1);
  opacity: 0; /* Initially hidden */
  transition: opacity 0.3s ease-in-out;
}

.sticky-toolbar-visible {
  opacity: 1; /* Visible when the user scrolls */
}

/* .k-submit-confirmation-modal {
  position: absolute;
  top: '50%';
  left: '50%';
  transform: 'translate(-50%, -50%)';
  width: 400;
  background-color: 'white';
  border: '2px solid #000';
  box-shadow: 24;
  p: 4;
} */

.k-submit-confirmation-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.k-submit-modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  max-width: 400px; /* Adjust the max-width as needed */
  width: 100%;
}

.k-submit-button-container {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
}

.k-submit-button-container button {
  margin-top: 10px;
}