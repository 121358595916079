.forget-login-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-family: "Atkinson Hyperlegible";
    margin-top: 2.25rem;
}

.forget-login-title {
    font-size: 1.25rem;
    text-align: center;
    font-weight: 700;
    color: black;
    margin-bottom:1.25rem;
}