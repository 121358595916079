.team-join-container{
    display: flex;
    justify-content: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: "Atkinson Hyperlegible";
}

.team-join-title{
    font-size: 1.25rem;
    text-align: center;
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 0.4rem;
   }


