.create-team-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-family: "Atkinson Hyperlegible";
    margin-top: 1.25rem;
}

.create-team-title {
    font-size: 1.25rem;
    text-align: center;
    font-weight: 700;
    color: black;
}

.login-sign-up-link{
    margin-top:1.25rem;
}

.create-team-btn-dismiss {
    padding: 8px 8px;
    font-size: 1rem;
    border: none;
    color: white;
    outline: none;
    border-radius: 0.25rem;
    font-family: "Atkinson Hyperlegible";
    background-color: black;
    height: 40px;
    display: block;
  margin-top: 10px;

  }

  .create-team-toast-overlay{
    background-color: rgba(31,31,31,0.59);
    position: absolute;
    width: 100%;
    height: 120%;
    z-index: 999;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    
  }