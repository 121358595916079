table {
    border-collapse: separate !important;
    border-spacing: 0;
    margin: auto;
    border: 1px solid #000;
  }
  
  thead {
    position: sticky;
    top: 0;
  }
  
  th,
  td {
    max-width: 100%;
  }

  

.MainDash{
    display: flex;
    justify-content: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin:auto;
    font-family: "Atkinson Hyperlegible";
    margin-top: 1.25rem;
    margin-bottom: 1.2rem;
    font-size: 1.25rem;
}

.main-dash-border{
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding:20px;
    width:1100px;
    margin-top: 1.2rem;
    margin-bottom: 1.2rem;
  }
  .main-dash-title-box{
    font-size: 1.32rem;
    text-align: center;
    display: flex;
    justify-content: left;
    text-align: left;
    margin-top:0.5rem;
  }



.table{
    max-width:54rem;
}

.userlist-title{
    font-family: "Atkinson Hyperlegible";
    font-weight: 700;
align-items: center;
    justify-content: center;
    display: flex;
    margin-top: 4.375rem;
    margin-left:0.125rem;
    text-align: center;
   }

   .ul-table{
    align-items: center;
    justify-content: center;
    text-align: center;
    max-width:100%;
    position: relative;
    margin-top:-1.7rem;
}

.main-dash-title{
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 3.75rem;
}

.create-team-nav-toast-overlay{
    background-color: rgba(31,31,31,0.59);
    position: absolute;
    width: 100%;
    height: 170%;
    z-index: 999;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    
  }

  .team-lead-confirmation-toast-overlay{
    background-color: rgba(31,31,31,0.59);
    position: absolute;
    width: 100%;
    height: 170%;
    z-index: 999;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    
  }

  .request-toast-overlay{
    background-color: rgba(31,31,31,0.59);
    position: absolute;
    width: 100%;
    height: 170%;
    z-index: 999;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    
  }


.main-dash-table{
    max-width: 48rem;
    align-items: center;
    align-content: center;
    text-align: left;
    flex-shrink: 0;
    margin-top: 1.25rem;
}

.table>thead {
    vertical-align: bottom;
    background-color:white;
}

.table>tbody {
    vertical-align: inherit;
    background-color: white;
}
   

@media screen and (max-width: 75rem) {
    .MainDash{
        justify-content:flex-start;
        margin-top: 1.25rem;
        margin-bottom: 1.2rem;
        font-size: 1.25rem;
    }
}

.invite-team-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-family: "Atkinson Hyperlegible";
    margin-top: 5rem;
    margin-bottom: 4rem;
}

.invite-team-title {
    font-size: 1.25rem;
    margin-top: 1.25rem;
    margin-bottom: 1.875rem;
    text-align: center;
    color: black;
}


.invite-title {
    font-size: 1.5625rem;
    text-align: center;
    font-weight: 700;
    color: black;
}

.team-member-title {
    font-size: 1.5625rem;
    margin-bottom: 1.875rem;
    text-align: center;
    font-weight: 700;
    color: black;
    margin-top: -6.25rem;
}
