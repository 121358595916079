.change-password-container{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-family: "Atkinson Hyperlegible";
    margin-top: 2.25rem;
    }

    
    .change-password-title{
        font-size: 1.25rem;
        text-align: center;
        font-weight: 700;
        color: black;
        margin-bottom:0.25rem;
    }

    
    .change-password-input-container{
        flex-direction: column;

    }

    .change-password-input-general{
        border-radius: 0.25rem;
        margin-top: 0.9375rem;
        background-color: aliceblue;
        border: 0.0625rem solid #d8e0ff;
        width: 21.25rem;
        height: 3.125rem;
        padding: 1.25rem;
    }

    .change-password-input-general-error{
        margin-right: 11.25rem;
        margin-top: -0.9375rem;
        color: darkred;
    }

    .button-container{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-top: 1.25rem;
    }

    .user-verify-redirect-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        text-align: center;
        font-family: "Atkinson Hyperlegible";
        margin-top: 1.25rem;
    }
    
    .user-verify-redirect-title{
        font-size: 32px;
        font-weight: bold;
        font-family: "Atkinson Hyperlegible";
        margin-bottom:10px;
    }
    
    .user-verify-redirect-details{
        font-size: 16px;
        font-family: "Atkinson Hyperlegible";
        line-height:20px;
        margin-bottom: 20px;
        color:rgb(149, 147, 147);
    }