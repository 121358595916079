.login-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-family: "Atkinson Hyperlegible";
    margin-top: 2.25rem;
}

.login-title {
    font-size: 1.25rem;
    text-align: center;
    font-weight: 700;
    color: black;
}

.login-sign-up-link{
    margin-top:1.25rem;
    display: flex;
    flex-direction: column;
}

.sign-up-link{
    color:#0969da;
}