.register-container{
    display: flex;
    justify-content: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: "Atkinson Hyperlegible";
    margin-top: 1.25rem;
    }

    .register-title {
        font-size: 1.25rem;
        text-align: center;
        font-weight: 700;
        color: black;
        margin-bottom:0.625rem;
    }

    p{
        margin-bottom:0.5rem;
    }


