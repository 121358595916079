.forget-password-redirect-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-family: "Atkinson Hyperlegible";
    margin-top: 1.25rem;
}

.forget-password-redirect-title{
    margin-top:20px;
    font-size: 32px;
    font-weight: bold;
    font-family: "Atkinson Hyperlegible";
    margin-bottom:10px;
}

.forget-password-redirect-error{
    text-align: left;
}

.user-verify-redirect-details{
    font-size: 16px;
    font-family: "Atkinson Hyperlegible";
    line-height:20px;
    margin-bottom: 20px;
    color:rgb(149, 147, 147);
}