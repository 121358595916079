  
.basic {
  padding: 0.7rem 1rem;
  font-size: 1rem;
  border: none;
  color: white;
  margin: 0.8rem;
  outline: none;
  border-radius: 0.25rem;
  min-width: 21.25rem;
  height: 2.5rem;
  font-family: "Atkinson Hyperlegible";
  background-color: black;
  margin-left: 1.25rem;
  width: 21.25rem;
  height: 3.125rem;


}

.admin-team-btn {
  padding: 5px 5px;
  font-size: 1rem;
  border: none;
  color: white;
  outline: none;
  border-radius: 0.25rem;
  font-family: "Atkinson Hyperlegible";
  background-color: black;
  margin-left: 180px;
  width: 130px;
  height: 40px;

}



.disabled-button{
   color: #adacac;
   border: 1px solid #cbcaca;
   padding: 0.7rem 1rem;
   font-size: 1rem;
   margin: 0.8rem;
   outline: none;
   border-radius: 0.25rem;
   min-width: 21.25rem;
   height: 2.5rem;
   font-family: "Atkinson Hyperlegible";
   background-color: #fff;
   margin-left: 1.25rem;
   width: 21.25rem;
   height: 3.125rem;
}

.verification-button{
  padding: 0.7rem 1rem;
  font-size: 1rem;
  border: none;
  color: white;
  margin: 0.8rem;
  outline: none;
  border-radius: 0.25rem;
  min-width: 21.25rem;
  height: 2.5rem;
  font-family: "Atkinson Hyperlegible";
  background-color: black;
  margin-left: 1.25rem;
  width: 21.25rem;
  height: 3.125rem;
margin-top: 1.25rem;
}

.spinner{
  display: flex;
  justify-content: center;
}

.back-button{ padding: 0.7rem 1rem;
  font-size: 1rem;
  border: none;
  color: white;
  margin: 0.8rem;
  outline: none;
  border-radius: 0.25rem;
  min-width: 21.25rem;
  height: 2.5rem;
  font-family: "Atkinson Hyperlegible";
  background: initial;
  border: 0.0625rem solid #fe0000;
  color: #fe0000;
  margin-left: 1.25rem;
  margin-bottom:1.875rem;
  width: 21.25rem;
  height: 3.125rem;
  
}

.back-button-team{ padding: 0.7rem 1rem;
  font-size: 1rem;
  border: none;
  color: white;
  margin: 0.8rem;
  outline: none;
  border-radius: 0.25rem;
  min-width: 21.25rem;
  height: 2.5rem;
  font-family: "Atkinson Hyperlegible";
  background: initial;
  border: 0.0625rem solid #fe0000;
  color: #fe0000;
  margin-left: 1.25rem;
  margin-bottom:0.625rem;
  width: 21.25rem;
  height: 3.125rem;
  
}


.participant-button {
  padding: 0.7rem 1rem;
  font-size: 1rem;
  border: none;
  color: white;
  margin: 0.8rem;
  outline: none;
  border-radius: 0.25rem;
  min-width: 21.25rem;
  height: 2.5rem;
  font-family: "Atkinson Hyperlegible";
  background-color: #f18155;
  margin-left: 1.25rem;
  width: 21.25rem;
  height: 3.125rem;
}

.team-button{
  padding: 0.7rem 1rem;
  font-size: 1rem;
  border: none;
  color: white;
  margin: 0.8rem;
  outline: none;
  border-radius: 0.25rem;
  min-width: 6.25rem;
  height: 2.5rem;
  font-family: "Atkinson Hyperlegible";
  background-color: #f18155;
  margin-left: 1.25rem;
  width: 6.25rem;
  height: 3.125rem;
}

.team-request-join-button {
  padding: 0rem 0rem;
  font-size: 1rem;
  border: none;
  color: white;
  margin: 0.2rem;
  outline: none;
  border-radius: 0.25rem;
  min-width: 5rem;
  height: 2.5rem;
  font-family: "Atkinson Hyperlegible";
  background-color: #f18155;
  width: 5rem;
  height: 3.125rem;
}

.submission-k-button,
.submission-k-button-disabled{
  padding: 0rem 0rem;
  font-size: 1rem;
  border: none;
  color: white;
  margin: 0.2rem;
  outline: none;
  border-radius: 0.25rem;
  min-width: 5rem;
  height: 2.5rem;
  font-family: "Atkinson Hyperlegible";
  background-color: green;
  width: 5rem;
  height: 3.125rem;
}

.submission-k-button-disabled{
  /* Additional styles for the disabled state */
  width: inherit; /* Inherit the width from the enabled state */
  opacity: 0.7; /* Optional: Adjust opacity for a visually disabled look */
  background-color: #ddd; /* Light gray background */
  color: #999; /* Darker gray text color */
  cursor: not-allowed; /* Display "not-allowed" cursor */
}

.submission-k-button-disabled:hover::before{
  /* Tooltip when disabled button is hovered over */
  content: 'Selection limit exceeded!'; /* Your custom hover text */
  position: absolute;
  top: -20px; /* Adjust the position based on your design */
  left: 85%; /* Adjust horizontal distance from left edge of the page */
  transform: translateX(-50%); /* Adjust horizontal distance from left edge of the page */
  background-color: #333; /* Tooltip background color */
  color: #fff; /* Tooltip text color */
  padding: 5px 10px; /* Adjust the padding based on your design */
  border-radius: 5px; /* Optional: add rounded corners */
  opacity: 0.9; /* Optional: adjust the transparency */
}


.invitation-action-button {
  margin:10px;
      font-size: 1rem;
      border: none;
      color: white;
      outline: none;
      border-radius: 0.25rem;
      font-family: "Atkinson Hyperlegible";
      background-color: black;
      width: 70px;
      height: 30px;
      display: block;
    
    }