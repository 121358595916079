.participant-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin:auto;
    font-family: "Atkinson Hyperlegible";
  }
  
  .pr-switch{
    display:flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-left:500px;
  }
  
  .pr-table-results{
    display: flex;
    justify-content: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: "Atkinson Hyperlegible";
    margin-bottom: 1.25rem;
    margin-top: 1.25rem; 
    max-width:18.75rem;
  }
  
  .pr-container{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-family: "Atkinson Hyperlegible";
    margin-top: 1.25rem;
  
  }
  
  .pr-row{
    display:flex;
    flex-direction: row;
  }
  
  .pr-title {
  font-size: 1.25rem;
  text-align: center;
  font-weight: 700;
  color: black;
  margin-right:1.25rem;
  margin-top:0.4rem;
  }
  
  
  
  
  .dropdown-link-participant-list {
    margin-left: 0rem;
    position: relative;
    margin-bottom: 0rem;
    margin-top: 0rem;
  }
  
  .pl-title{
    align-items: center;
    justify-content: center;
    display: flex;
    text-align: center;
    margin-top: 3.75rem;
  }
  
  .pl-table{
  align-items: center;
    justify-content: center;
    text-align: center;
    max-width:95%;
    position: relative;
  }
  
  .pl-filter-labels{
    align-items: center;
    justify-content: center;
    display: flex;
    text-align: center;
    margin-top: 3.75rem;
    max-width:95%;
  }
  
  .pl-pagination-buttons{
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 3.75rem;
    max-width:95%;
  }
  
  