
.llm-model-name{
  font-family: 'Consolas', 'Courier New', monospace;
}

.the-title{
  font-family: "Atkinson Hyperlegible";
  text-align: center;
  font-size: 2.5rem;
  margin-top:1.5625rem;
  color:black;
  font-weight: 700;
}

.figure-two{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.left-align-figure-two{
  display: flex;
  justify-content: left;
  flex-direction: column;
  align-items: left;
  text-align: left;
}

.fig-img-size {
  width:40%;
  height:40%;
}

.fig2-img-size {
  width:25%;
  height:25%;
}

.fig3-img-size {
  width:55%;
  height:55%;
}

.table-img-size {
  width:65%;
  height:65%;
}

.table2-img-size {
  width:45%;
  height:45%;
}

.url-text-styling {
  color: blue; 
  text-decoration: underline;
}

.subheaders-font-style{
  font-size: 1.20rem;
  font-weight: 600;
}

.image-size-table{
  width:600px;
  height:100px;
}


.image-size-llm{
  width:650px;
  height:1000px;
}

.image-size-animal{
  width:450px;
  height:650px;
}

.accordion {
  max-width: 100%;
  margin: 5rem 0 0;
  border-radius: 0.1875rem;
  width: 71.875rem;
  margin-left: 1.5625rem;
  min-width: 100%;
}

.accordion-visible {
  background: #0c63e4;
  width: 100%;
  color: black;
  cursor: pointer;
  border: 0.0625rem solid #c09673;;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.4375rem 0.9375rem;
  border-top-left-radius: 0.1875rem;
  border-top-right-radius: 0.1875rem;
}
.accordion-visible span {
  padding: 0.9375rem 0;
  font-size: 1.25rem;
}
.accordion-visible img {
  width: 1.25rem;
  transform: rotate(-90deg);
  transition: transform 0.2s ease-in-out;
}
.accordion-visible img.active {
  transform: rotate(0deg)
}

.accordion-visible h2 {
  font-size: 1.25rem;
}

.accordion-toggle {
  height: 0rem;
  font-size: 1.125rem;
  opacity: 0;
  transition:  
  opacity 0.3s ease-in-out,
  height 0.3s 0.3s ease-in-out;
  color: #333;
  border-bottom-left-radius: 0.1875rem;
  border-bottom-right-radius: 0.1875rem;
}

.animated {
  opacity: 1;
  transition:  
  height 0.3s ease-in-out,
  opacity 0.3s 0.3s ease-in-out;
}

.accordion-toggle p {
  background: white;
  margin: 0;
  padding: 1.375rem 0.9375rem;
  pointer-events: none;
  line-height: 1.3;
}