body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
      Arial, sans-serif;
    line-height: 1.4;
  }
  
  .wrapper {
    margin-top: 2.5rem;
    padding-bottom: 1.875rem;
}

  
  .accordion-wrapper {
    margin-top: 1.25rem;
    flex-direction: column;
    text-align: left;
    margin:auto;
    font-family: "Atkinson Hyperlegible";
    width: 87.5rem;
    max-width: 100%;
}

  
  .accordion-item {
    overflow: hidden;
    transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
    height: auto;
    max-height: 624.9375rem;
    border: solid 0.0625rem #ccc;
    background-color: white;
  }
  
  .accordion-item.collapsed {
    max-height: 0;
    transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
  }
  
  .accordion-title {
    font-weight: 600;
    cursor: pointer;
    color: #666;
    padding: 0.5em 1.2em;
    border: solid 0.0625rem #ccc;
    font-size:1.25rem;
    display: flex;
    justify-content: sp ace-between;
    align-items: center;
  
    &::after {
      content: "";
      width: 0;
      height: 0;
      border-left: 0.3125rem solid transparent;
      border-right: 0.3125rem solid transparent;
      border-top: 0.3125rem solid currentColor;
    }

  
    &:hover,
    &.open {
      color: black;
    }
  
    &.open {
      &::after {
        content: "";
        border-top: 0;
        border-bottom: 0.3125rem solid;
      }
    }
  }
  


  .accordion-content {
    padding: 1em 1.5em;
    font-size: 1rem;
    line-height: 1.5rem;
}  